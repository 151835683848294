import { defineComponent, toRefs, reactive, computed } from "vue";
import Eldialog from "@/components/Eldialog/index.vue";
import { ElCol, ElRow } from "element-plus";
export default defineComponent({
  name: "OperateInfo",
  components: {
    Eldialog,
    ElCol,
    ElRow
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    operateData: {
      type: Object
    }
  },
  emits: ["update:visible"],
  setup(props, {
    emit
  }) {
    const refData = reactive({
      // alarmType:['',''],
      title: "操作详情",
      info: [{
        title: '操作人用户名称',
        align: 'center',
        key: 'userName',
        visible: true
      }, {
        title: '操作人用户账户',
        align: 'center',
        key: 'userId',
        visible: true
      }, {
        title: '请求ip地址',
        align: 'center',
        key: 'ip',
        visible: true
      },
      // {
      //   title: '请求方法',
      //   align: 'center',
      //   key: 'method',
      //   visible: true,
      // },
      {
        title: '创建时间',
        align: 'center',
        key: 'createTime',
        visible: true
      },
      // {
      //   title: '耗费时间',
      //   align: 'center',
      //   key: 'costTime',
      //   visible: true,
      // },
      // {
      //   title: '请求参数',
      //   align: 'center',
      //   key: 'requestParam',
      //   slots:{customRender:'requestParam'},
      //   visible: true,
      //   width:100,
      // },
      {
        title: '内容',
        align: 'center',
        key: 'logContent',
        slots: {
          customRender: 'logContent'
        },
        width: 100,
        visible: true
      }],
      dataInfo: {},
      type: 0
    });
    // 初始化表单
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
      }
    });
    // 确定按钮
    const confirm = () => {
      emit("update:visible", false);
    };
    return {
      ...toRefs(refData),
      show,
      confirm
    };
  }
});