import { reactive, toRefs, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { useDebounce } from '@/hooks/core/useDebounce';
import API from "@/api/report/index";
import { useMessage } from '@/hooks/web/useMessage';
import dayjs from "dayjs";
import OperateInfo from "./components/OperateInfo.vue";
import { Search } from "@element-plus/icons-vue";
import { formatDate } from '@/utils/formatTime';
export default {
  name: "Operate",
  components: {
    // 自定义组件
    BasicTemplate: defineAsyncComponent(() => import('@/components/BasicTemplateTablePage/index.vue')),
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue')),
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue')),
    OperateInfo
  },
  setup() {
    const store = useStore();
    const message = useMessage();
    const refData = reactive({
      loading: false,
      total: 0,
      current: 1,
      pageSize: 50,
      searchForm: {
        source: 1,
        orgId: null,
        imei: '',
        time: [dayjs(new Date().getTime() - 60 * 60 * 24 * 3 * 1000).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        userName: '',
        operateType: 0,
        module: 0
      },
      tableData: [],
      activeInfo: {},
      tableLoading: false,
      infoVisible: false,
      tableColumns: [{
        title: '序号',
        align: 'center',
        key: 'index',
        width: 60,
        slots: {
          customRender: 'index'
        },
        visible: true
      }, {
        title: '操作人用户名称',
        align: 'center',
        key: 'userName',
        visible: true
      }, {
        title: '操作人用户账户',
        align: 'center',
        key: 'userId',
        visible: true
      }, {
        title: '请求ip地址',
        align: 'center',
        key: 'userIp',
        visible: true
      },
      // {
      //   title: '请求方法',
      //   align: 'center',
      //   key: 'method',
      //   slots:{customRender:'method'},
      //   visible: true,
      // },
      // {
      //   title: '请求参数',
      //   align: 'center',
      //   key: 'requestParam',
      //   slots:{customRender:'requestParam'},
      //   visible: true,
      // },
      {
        title: '创建时间',
        align: 'center',
        key: 'createTime',
        visible: true
      },
      // {
      //   title: '耗费时间',
      //   align: 'center',
      //   key: 'costTime',
      //   visible: true,
      // },
      {
        title: '内容',
        align: 'center',
        key: 'subType',
        visible: true
      }, {
        title: '详情',
        align: 'center',
        key: 'action',
        visible: true
      }]
    });
    const onSearch = async () => {
      refData.loading = true;
      const params = {
        pageNo: refData.current,
        pageSize: refData.pageSize,
        source: refData.searchForm.source,
        orgId: refData.searchForm.orgId ? refData.searchForm.orgId : '',
        imei: refData.searchForm.imei,
        operateType: refData.searchForm.operateType,
        module: refData.searchForm.module,
        startTime: refData.searchForm.time && refData.searchForm.time.length ? refData.searchForm.time[0] + ' 00:00:00' : '',
        endTime: refData.searchForm.time && refData.searchForm.time.length ? refData.searchForm.time[1] + ' 23:59:59' : '',
        userName: refData.searchForm.userName.replace(/[.\\[\]{}()|^$?*+]/, '')
      };
      try {
        if (params.userName.length > 20) throw {
          msg: 'IMEI号错误，请输入正确IMEI号'
        };
        const {
          data,
          msg,
          code
        } = await API.operateLog(params);
        refData.loading = false;
        if (code == 0) {
          refData.total = data === null || data === void 0 ? void 0 : data.total;
          refData.tableData = data.list.map(item => {
            item.createTime = formatDate(item.createTime);
            return item;
          });
        } else {
          refData.total = 0;
          refData.tableData = [];
        }
        if (code != 0) throw msg;
      } catch (error) {
        refData.loading = false;
        message.warning(error);
      }
    };
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    const pageSizeChange = val => {
      refData.pageSize = val;
      debounceOnSearch();
    };
    // 结构树回调
    const handleClick = data => {
      refData.searchForm.orgId = data.id;
      debounceOnSearch();
    };
    //导出
    const handleExport = async () => {
      refData.tableLoading = true;
      const params = {
        source: refData.searchForm.source,
        imei: refData.searchForm.imei,
        operateType: refData.searchForm.operateType,
        module: refData.searchForm.module,
        orgId: refData.searchForm.orgId ? refData.searchForm.orgId : '',
        startTime: refData.searchForm.time && refData.searchForm.time.length ? refData.searchForm.time[0] + ' 00:00:00' : '',
        endTime: refData.searchForm.time && refData.searchForm.time.length ? refData.searchForm.time[1] + ' 23:59:59' : '',
        userName: refData.searchForm.userName.replace(/[.\\[\]{}()|^$?*+]/, '')
      };
      await API.operateExcel(params);
      refData.tableLoading = false;
    };
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleClick,
      handleExport,
      Search
    };
  }
};